import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// import { isBidDepotProject } from '../../../utils'
import { IconButton, InputBase, makeStyles, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 510,
        backgroundColor: '#F2F2F7',
    },
    input: {
        flex: 1,
        padding: '2px 4px',
        color: '#49454f',
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}))

const HeaderSearchComponent = (props) => {
    const classes = useStyles()
    const [searchValues, setSearchValues] = useState({
        searchbar: '',
        zipcode: '',
        miles: '',
        category: [],
    })
    const handleChange = (e) =>
        setSearchValues({ ...searchValues, [e.target.name]: e.target.value })
    let history = useHistory()

    const handleClick = (e) => {
        // e.preventDefault()
        history.push(
            `/search?q=${searchValues.searchbar}&z=${searchValues.zipcode}&m=${
                searchValues.miles
            }&cat=${
                searchValues.category.length > 0 ? searchValues.category.join(',') : ''
            }&view=0`,
        )
    }
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        setSearchValues({
            ...searchValues,
            searchbar: params.get('q') ? params.get('q') : '',
            zipcode: params.get('z') ? params.get('z') : '',
            miles: params.get('m') ? params.get('m') : '',
            category: params.get('cat') ? params.get('cat').split(',') : '',
        })
    }, [])

    const isBidDepotProject = document.querySelector('body').classList.contains('BidAuction')

    return (
        <form
            className="nav-standard-top-area-search"
            onSubmit={(e) => {
                e.preventDefault()
                handleClick(e) // Call your custom search logic
            }}
        >
            <div className="nav-standard-top-area-search-inner">
                {isBidDepotProject ? (
                    <Paper className={classes.root}>
                        {window.screen.width > 1024 ? (
                            <IconButton
                                type="submit"
                                className={`${classes.iconButton} lt`}
                                aria-label="menu"
                            >
                                View All Items
                                {/* <span className="material-icons" onClick={handleClick}>
                                    menu
                                </span> */}
                            </IconButton>
                        ) : (
                            <IconButton
                                type="submit"
                                className={classes.iconButton}
                                aria-label="search"
                            >
                                <span className="material-icons" onClick={handleClick}>
                                    search
                                </span>
                            </IconButton>
                        )}
                        <InputBase
                            className={`nav-standard-top-area-search-textInput ${classes.input} ${
                                window.screen.width > 1024 ? 'rt' : "'"
                            }`}
                            placeholder="Search Items"
                            inputProps={{ 'aria-label': 'search google maps' }}
                            type="search"
                            onChange={handleChange}
                            name="searchbar"
                            value={searchValues.searchbar}
                        />
                        {window.screen.width > 1024 ? (
                            <IconButton
                                type="submit"
                                className={classes.iconButton}
                                aria-label="search"
                            >
                                <span className="material-icons" onClick={handleClick}>
                                    search
                                </span>
                            </IconButton>
                        ) : (
                            ''
                        )}
                    </Paper>
                ) : (
                    <>
                        <div>
                            <span className="material-icons srchIcon" onClick={handleClick}>
                                search
                            </span>
                            <input
                                type="search"
                                onChange={handleChange}
                                name="searchbar"
                                value={searchValues.searchbar}
                                className="nav-standard-top-area-search-input open-sans"
                                placeholder="Search Inventory"
                            />
                            {/* <select
                                className="custom-select"
                                name="miles"
                                onChange={handleChange}
                                value={searchValues.miles}
                            >
                                <option selected>Kms</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select> */}
                        </div>
                        <button
                            onClick={handleClick}
                            className="lin lin-magnifier nav-standard-top-area-search-sumbit searching-inventory"
                        >
                            Search
                        </button>
                    </>
                )}
            </div>
        </form>
    )
}

export default HeaderSearchComponent
